.login {
  padding: 150px 20px;

  &__img-container {
    display: flex;
    justify-content: center;

    margin-bottom: 30px;
  }

  &__img {
    height: 30px;
  }

  &__error-message {
    margin-bottom: 12px;
    padding: 10px;

    text-align: center;

    color: white;
    border-radius: 3px;
    background-color: #e31931;

    font-size: 14px;
  }
}
