.admin-cleaning-ui {
  &__tabs {
    border-bottom: 1px solid #e8e8e8;

    line-height: 25px;
  }
  

  .ant-table-scroll {
    & td:last-child,
    & th:last-child {
      display: none;
    }
  }

  .ant-table-fixed-right {
    td {
      padding: 0 20px !important;
    }
  }

}
