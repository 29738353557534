.art-autosuggest {
  position: absolute;
  z-index: 999999;
  background: white;
  border: gray solid 1px;
  width: 100%;
  border-radius: 5px;
  padding: 0;

  .art-autosuggest-item {
    color: #101115;
    cursor: pointer;
    padding: 0 5px;
    border-bottom: 1px solid #a3a3a3;
    &:hover {
      background: #40a9ff;
      color: #ffffff;
    }
  }
}