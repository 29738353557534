.cleaning-search {
    margin: 16px 0;
    .ant-collapse {
        background: white;
        border: none;
        & > .ant-collapse-item {
            border-top: none;
            border-bottom: none;
            & > .ant-collapse-header {
                width: 200px;
                &:hover {
                    color: #1890ff;
                }
            }
            &:last-child > .ant-collapse-content {
                border-radius: 4px;
            }
        }
        .ant-collapse-content {
            border: 1px solid #d9d9d9;
        }
    }

    .ant-form-item {
        margin-bottom: 8px;
    }
}
