.image-uploader {
  width: 100%;
  height: 100%;

  .ant-upload-list-item.ant-upload-list-item-done {
    width: 100%;
    height: 100%;
  }

  .ant-upload-list.ant-upload-list-picture-card {
    width: 100%;
    height: 100%;
  }
}
