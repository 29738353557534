.tabs {
  display: flex;

  line-height: 46px;

  &__tab {
    padding: 0 20px;

    cursor: pointer;
    transition: 200ms;
    text-decoration: none;

    color: black;
    border-bottom: 2px solid transparent;

    line-height: inherit;

    &:hover,
    &_selected {
      color: #1890ff;
      border-bottom-color: #1890ff;
    }
  }
}
