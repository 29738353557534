.layout {
  padding: 14px 24px;

  &__header {
    display: flex;
    justify-content: space-between;

    box-sizing: border-box;
    margin-bottom: 24px;

    border-bottom: 1px solid #e8e8e8;

    line-height: 46px;
  }
}
