.cleaning-ui {
  &__tabs {
    border-bottom: 1px solid #e8e8e8;

    line-height: 25px;
  }

  &__buttons {
    margin: 16px 0;
  }

  .ant-table td { 
    white-space: nowrap; 
  }

  .ant-table td, 
  .ant-table th { 
    & > div {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    } 
  }

  .ant-table-scroll {
    & td:last-child,
    & th:last-child {
      display: none;
    }
  }

  .ant-table-fixed-right {
    td {
      padding: 0px 16px;
    }
  }
}
