.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  padding-bottom: 0px;
}

.ant-table-filter-dropdown-btns {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}