.edit-artist {
    &__title {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    &__spin {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
    }
}
