.auctions-dropdown {

    &__labels {
        padding: 5px 13px;

        color: #1890ff;
    }
    
    &__fields {
        display: flex;
        align-items: center;
    }
    
    &__field {
        
        &:not(:first-of-type){
            margin: 0 3px;
        }

        &_name {
            max-width: 380px;
            
            text-overflow: ellipsis;
            overflow: hidden; 
        }
    }
}
