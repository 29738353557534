.admin-cleaning-form {
    padding: 20px;
    
    .ant-form-item-label {
        width: 150px;

        text-align: left;

    }
    &__dropdown {
        .ant-form-item-control-wrapper {
            width: 171px;
        }
    }  
}